<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="名片名称" prop="name" >
        <a-input v-model="form.name" placeholder="请输入名片名称" />
      </a-form-model-item>
      <a-form-model-item label="名片副标题" prop="subtitle" >
        <a-input v-model="form.subtitle" placeholder="请输入名片副标题" />
      </a-form-model-item>
      <a-form-model-item label="名片类型（暂未用）" prop="type">
        <a-select style="width: 100%" v-model="form.type" placeholder="请选择名片类型" allow-clear>
          <a-select-option v-for="(item, index) in this.customDict.CardTypeEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="名片等级" prop="level" >
        <a-select style="width: 100%" v-model="form.level" placeholder="请选择名片等级">
          <a-select-option v-for="(item, index) in this.customDict.CardLevelEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="分享名片图片" prop="shareCardImage" >
        <oss-multi-upload
          v-model="form.shareCardImage"
          type="img"
          bucket="card"
          :maxCount="1"
          :isMultiple="false"
        ></oss-multi-upload>
      </a-form-model-item>
      <a-form-model-item label="名片图片" prop="cardImage" >
        <oss-multi-upload
          v-model="form.cardImage"
          type="img"
          bucket="card"
          :maxCount="1"
          :isMultiple="false"
        ></oss-multi-upload>
      </a-form-model-item>
      <a-form-model-item label="状态" prop="status" >
        <a-select style="width: 100%" v-model="form.status" placeholder="请选择状态">
          <a-select-option v-for="(item, index) in this.customDict.CardStatusEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
<!--      <a-form-model-item label="逻辑删除标记，1：删除，0：未删除，默认0" prop="isDeleted" v-if="formType === 1">-->
<!--        <a-input v-model="form.isDeleted" placeholder="请输入逻辑删除标记，1：删除，0：未删除，默认0" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
<!--      <a-form-model-item label="乐观锁" prop="version" v-if="formType === 1">-->
<!--        <a-input v-model="form.version" placeholder="请输入乐观锁" />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getCard, addCard, updateCard } from '@/api/card/card'
import { mapGetters } from 'vuex'
import OssMultiUpload from '@/views/script/script/modules/OssMultiUpload.vue'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    OssMultiUpload
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        name: null,
        subtitle: null,
        type: undefined,
        level: undefined,
        shareCardImage: null,
        cardImage: null,
        status: undefined,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [
          { required: true, message: '名片名称不能为空', trigger: 'blur' }
        ],
        subtitle: [
          { required: true, message: '名片副标题不能为空', trigger: 'blur' }
        ],
        // type: [
        //   { required: true, message: '名片类型 暂未用不能为空', trigger: 'change' }
        // ],
        level: [
          { required: true, message: '请选择名片等级', trigger: 'change' }
        ],
        shareCardImage: [
          { required: true, message: '请选择分享名片图片', trigger: 'blur' }
        ],
        cardImage: [
          { required: true, message: '请选择名片图片', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择状态', trigger: 'change' }
        ]
        // isDeleted: [
        //   { required: true, message: '逻辑删除标记，1：删除，0：未删除，默认0不能为空', trigger: 'blur' }
        // ],
        // version: [
        //   { required: true, message: '乐观锁不能为空', trigger: 'blur' }
        // ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        name: null,
        subtitle: null,
        type: undefined,
        level: undefined,
        shareCardImage: null,
        cardImage: null,
        status: undefined,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getCard(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateCard(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addCard(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
