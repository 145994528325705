import request from '@/utils/request'

// 查询名片夹列表
export function listCard(query) {
  return request({
    url: '/card/card/list',
    method: 'get',
    params: query
  })
}

// 查询名片夹详细
export function getCard(id) {
  return request({
    url: '/card/card/' + id,
    method: 'get'
  })
}

// 新增名片夹
export function addCard(data) {
  return request({
    url: '/card/card',
    method: 'post',
    data: data
  })
}

// 修改名片夹
export function updateCard(data) {
  return request({
    url: '/card/card',
    method: 'put',
    data: data
  })
}

// 删除名片夹
export function delCard(id) {
  return request({
    url: '/card/card/' + id,
    method: 'delete'
  })
}
